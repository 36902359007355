import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./loginPage.css";
import SpinnerLoader from "../../spinnerLoader/SpinnerLoader";
import { logIn } from "../../../helpers/api";
import { Alert, Spinner } from "react-bootstrap";

export default function LoginPageKtherobot() {
  const [showPassword, setShowPassword] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [user, setUser] = useState({ username: "", password: "" });
  const [showLoading, setShowLoading] = useState(false);
  const [loginError, setloginError] = useState("");
  const navigate = useNavigate();
  const handlerUsername = (e) => {
    e.preventDefault();
    setUser({ ...user, username: e.target.value });
  };

  const handlerPassword = (e) => {
    e.preventDefault();
    setUser({ ...user, password: e.target.value });
  };

  const handlerLogin = (e) => {
    setShowLoading(true);
    e.preventDefault();
    logIn(user)
      .then((response) => {
        setShowLoading(false);
        navigate("/applications");
      })
      .catch((error) => {
        setShowLoading(false);
        setloginError(error);
      });
  };

  useEffect(() => {
    setShowSpinner(false);
  }, []);

  useEffect(() => {
    setloginError("");
  }, [user]);
  const handleShowPassword = (show) => {
    setShowPassword(show);
  };

  const ktherobot = () => (
    <>
      <Container className="text-end ig-login-ktherobot">
        <img src="img/logo-3.png" alt="Brand" />
        <h1>Welcome To Ktherobot</h1>
        <p>
          This is your main source for Diagrams, Technical information, DTCS,
          etc for your cars. Be ahead of the others using our software.
        </p>
      </Container>
    </>
  );

  const longInForm = () => {
    return (
      <>
        <Container className="ig-login-loginform">
          <h2 style={{ fontWeight: "bold" }} className="w-100 text-center">
            Login
          </h2>
          <p style={{ fontWeight: "bold" }} className="w-100 text-center">
            Login into your account
          </p>

          {loginError !== "" ? (
            <Alert variant="warning">{loginError}</Alert>
          ) : (
            <></>
          )}

          <Form style={{ color: "#555" }}>
            <Form.Group className="text-end" controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control
                className="text-end"
                type="email"
                placeholder="demo@gmail.com"
                value={user.username}
                onChange={handlerUsername}
              />
            </Form.Group>

            <Form.Group className="mt-2 text-end" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                className="text-end"
                type={showPassword ? "text" : "password"}
                placeholder="*******"
                value={user.password}
                onChange={handlerPassword}
              />
            </Form.Group>
            <div
              style={{
                width: "fit-content",
                left: "0.5em",
                position: "relative",
                transform: "translateY(-135%)",
              }}
            >
              {showPassword ? (
                <FontAwesomeIcon
                  icon={faEye}
                  onClick={() => {
                    handleShowPassword(false);
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  onClick={() => {
                    handleShowPassword(true);
                  }}
                />
              )}
            </div>
            <Form.Group>
              <Button
                variant="primary"
                className="w-100"
                type="submit"
                onClick={handlerLogin}
                disabled={showLoading}
              >
                {showLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
                Log In
              </Button>
            </Form.Group>
          </Form>
          <div>
            <p>Don't have an account? Text us on whatsapp +1(305)851-2754</p>
          </div>
        </Container>
      </>
    );
  };

  return (
    <div className="ig-login-background">
      <div className="ig-login-background-cover">
        {ktherobot()}
        {longInForm()}
        <SpinnerLoader show={showSpinner} />
      </div>
    </div>
  );
}
