import React, { useEffect, useState } from "react";
import "./userManagement.css";
import PrettyContainer from "../../prettyContainer/PrettyContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faKey,
  faLock,
  faLockOpen,
  faEraser,
  faScrewdriverWrench,
  faSort,
  faSortUp,
  faSortDown
} from "@fortawesome/free-solid-svg-icons";
import { Alert, Badge, Button, Modal, Spinner } from "react-bootstrap";
import AddUser from "../../addUser/AddUser";
import UpdatePassword from "../../updatePassword/UpdatePassword";
import UpdateApplications from "../../updateApplications/UpdateApplications";
import {
  clearUserUUIDs,
  deleteSpecificUser,
  getUsers,
  updateInfoToUser,
} from "../../../helpers/api";
import { config as applicationsConfig } from "../Applications/config";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function UserManagementPage() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState({ keywords: "", toggleSearch: false, dueDate: null, dueDateOperator : "lt", dueDateSort: 0, enabledBy:"", enabled: "-1" });
  const [showLoading, setShowLoading] = useState(false);
  const [connectionSuccess, setConnectionSuccess] = useState("");
  const [connectionError, setConnectionError] = useState("");
  const actionRef = {
    clearUUID: 0,
    unlockUser: 1,
    lockUser: 2,
    deleteUser: 3,
  };

  const forceSearch = () => {
    setSearch({ ...search, toggleSearch: !search.toggleSearch });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getUsers(search)
        .then((list) => {
          setUsers(list);
        })
        .catch((errors) => {
          console.log(errors);
        });
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [search]);

  const handleSearchKeyWords = (e) => {
    e.preventDefault();
    setSearch({ ...search, keywords: e.target.value });
  };

  const handleSearchDueDate = (dueDate) => {
      setSearch({ ...search, dueDate: dueDate });
  };

  const handleDueDateSort = (sortDirection) => {
    setSearch({...search, dueDateSort: sortDirection})
  }

  const handleDueDateOperator = (e) => {
    e.preventDefault();
    setSearch({...search, dueDateOperator: e.target.value});
  }

  const handleEnabledUserBy = (e) => {
    e.preventDefault();
    setSearch({...search, enabledBy: e.target.value})
  }

  const handleEnabledUser  = (e) => {
    e.preventDefault();
    setSearch({...search, enabled: e.target.value})
  }

  const [showAddNewUSer, setShowAddNewUser] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState({
    show: false,
    user: { username: "" },
  });
  const [showUpdateApplications, setShowUpdateApplications] = useState({
    show: false,
    user: { username: "" },
  });
  const [modalInfo, setModalInfo] = useState({
    user: {},
    show: false,
    tittle: "",
    body: "",
    confirm: () => {},
    cancel: () => {},
    action: -1,
  });

  useEffect(() => {
    if (!modalInfo.show) {
      setConnectionSuccess("");
      setConnectionError("");
    }
  }, [modalInfo]);

  const handleCancelNewUser = () => setShowAddNewUser(false);
  const handleShowNewUser = () => setShowAddNewUser(true);
  const handleCancelUpdatePassword = () =>
    setShowUpdatePassword({ ...showUpdatePassword, show: false });
  const handleCancelUpdateApplications = () =>
    setShowUpdateApplications({ ...showUpdateApplications, show: false });
  const handleShowUpdatePassword = (info) => setShowUpdatePassword(info);

  const handleModalInfo = (info) => {
    setModalInfo({ ...modalInfo, ...info });
  };

  const handleUpdateApplications = (info) => {
    setShowUpdateApplications(info);
  };

  const handlerActions = (e) => {
    e.preventDefault();
    switch (modalInfo.action) {
      case actionRef.clearUUID:
        clearRegisteredBrowser();
        break;
      case actionRef.deleteUser:
        deleteUser();
        break;
      case actionRef.unlockUser:
        unlockUser(true);
        break;
      case actionRef.lockUser:
        unlockUser(false);
        break;
      default:
        break;
    }
  };
  const deleteUser = () => {
    setShowLoading(true);
    deleteSpecificUser(modalInfo.user)
      .then((response) => {
        setShowLoading(false);
        setConnectionError("");
        setConnectionSuccess(response.message);
        forceSearch();
      })
      .catch((error) => {
        setShowLoading(false);
        setConnectionError(error);
        setConnectionSuccess("");
      });
  };
  const unlockUser = (value) => {
    setShowLoading(true);
    updateInfoToUser(modalInfo.user, { enabled: value })
      .then((response) => {
        setShowLoading(false);
        setConnectionError("");
        setConnectionSuccess(response.message);
        forceSearch();
      })
      .catch((error) => {
        setShowLoading(false);
        setConnectionError(error);
        setConnectionSuccess("");
      });
  };
  const clearRegisteredBrowser = (e) => {
    setShowLoading(true);
    clearUserUUIDs(modalInfo.user)
      .then((response) => {
        setShowLoading(false);
        setConnectionError("");
        setConnectionSuccess(response.message);
      })
      .catch((error) => {
        setShowLoading(false);
        setConnectionError(error);
        setConnectionSuccess("");
      });
  };

  const handleCloseModal = () => handleModalInfo({ show: false });

  const mapUsers = (users) => {
    return users.map((user, i) => {
      return (
        <tr key={i}>
          <td>{user.username}</td>
          <td>{moment(user.dueDate).format("YYYY-MM-DD HH:mm:ss")}</td>
          <td>{user.enableReason === 0 ? 
            (<span>E. By. Admin </span>) : 
              user.enableReason === 1 ? 
                (<span>D. By. Admin</span>):
                (<span>D. By. Due</span>)}
          </td>
          <td>
            {user.applications.map((app, i) => {
              let component = null;
              applicationsConfig.applications.forEach((appC) => {
                if (app === appC.id) {
                  component = (
                    <Badge key={i} bg="primary" style={{ marginRight: "4px" }}>
                      {appC.tittle}
                    </Badge>
                  );
                }
              });
              if (component !== null) {
                return component;
              }
            })}
          </td>
          <td>
            {user.enabled ? (
              <Badge bg="success">Active</Badge>
            ) : (
              <Badge bg="secondary">Inactive</Badge>
            )}
          </td>

          <td className="ig-userManagement-table-actions-col">
            <span className="update-applications-user">
              <FontAwesomeIcon
                icon={faScrewdriverWrench}
                onClick={() => {
                  handleUpdateApplications({ show: true, user: user });
                }}
              />
            </span>
            {user.enabled ? (
              <span className="lock-user">
                <FontAwesomeIcon
                  icon={faLock}
                  onClick={() => {
                    handleModalInfo({
                      user: user,
                      show: true,
                      tittle: `Disable user ${user.username}`,
                      body: `Do you confirm action disable?`,
                      action: actionRef.lockUser,
                    });
                  }}
                />
              </span>
            ) : (
              <span className="unlock-user">
                <FontAwesomeIcon
                  icon={faLockOpen}
                  onClick={() => {
                    handleModalInfo({
                      user: user,
                      show: true,
                      tittle: `Enable User ${user.username}`,
                      body: `Do you confirm action enable?`,
                      action: actionRef.unlockUser,
                    });
                  }}
                />
              </span>
            )}

            <span className="clear-user">
              <FontAwesomeIcon
                icon={faEraser}
                onClick={(e) => {
                  handleModalInfo({
                    user: user,
                    show: true,
                    tittle: `Reset lock devices for ${user.username}`,
                    body: `Do you confirm action clear?`,
                    force: !modalInfo.force,
                    action: actionRef.clearUUID,
                  });
                }}
              />
            </span>
            <span className="key-user">
              <FontAwesomeIcon
                icon={faKey}
                onClick={() => {
                  handleShowUpdatePassword({ show: true, user: user });
                }}
              />
            </span>
            <span className="delete-user">
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => {
                  handleModalInfo({
                    user: user,
                    show: true,
                    tittle: `Deleting User ${user.username}`,
                    body: `Do you confirm action delete?`,
                    action: actionRef.deleteUser,
                  });
                }}
              />
            </span>
          </td>
          <td>{moment(user.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
        </tr>
      );
    });
  };

  const usersTable = () => (
    <PrettyContainer tittle="USER MANAGEMENT">
      <Button
        onClick={handleShowNewUser}
        style={{ position: "relative", left: "90%" }}
        variant="success"
      >
        Add New USer
      </Button>

      <table className="ig-userManagement-table">
        <thead>
          <tr>
            <th>User</th>
            <th>Due Date</th>
            <th>E/D By</th>
            <th>Applications</th>
            <th>Status</th>
            <th>Actions</th>
            <th>Created At</th>
          </tr>
          <tr>
            <th>
              <input
                type="text"
                value={search.keywords}
                onChange={handleSearchKeyWords}
              ></input>
            </th>
            <th>
            <div style={{ display: "flex", alignItems: "center" }}>
              <select value={search.dueDateOperator} onChange={handleDueDateOperator}>
                <option value="lt">&lt;</option>
                <option value="gt">&gt;</option>
                <option value="eq">=</option>
              </select>
              <DatePicker
                selected={search.dueDate}
                onChange={(date) => handleSearchDueDate(date)}
                highlightDates={[moment.now()]}
                isClearable={true}
              />
  {search.dueDateSort === 0 ? (
    <FontAwesomeIcon
      icon={faSort}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        handleDueDateSort(1);
      }}
    />
  ) : search.dueDateSort === 1 ? (
    <FontAwesomeIcon
      icon={faSortUp}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        handleDueDateSort(-1);
      }}
    />
  ) : (
    <FontAwesomeIcon
      icon={faSortDown}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        handleDueDateSort(1);
      }}
    />
  )}
</div>
            
            
              

            </th>
            <th>
              <select value={search.enabledBy} onChange={handleEnabledUserBy}>
                <option value=""></option>
                <option value="0">E. by Admin</option>
                <option value="1">D. by Admin</option>
                <option value="2">D. by Due</option>
              </select>
            </th>
            <th></th>
            <th><select value={search.enabled} onChange={handleEnabledUser}>
              <option value="-1"></option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>{mapUsers(users)}</tbody>
      </table>
    </PrettyContainer>
  );

  const modalPopUp = () => {
    return (
      <>
        <Modal
          show={modalInfo.show}
          onHide={handleCloseModal}
          animation={true}
          centered={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalInfo.tittle}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {connectionError !== "" ? (
              <Alert variant="warning">{connectionError}</Alert>
            ) : (
              <></>
            )}
            {connectionSuccess !== "" ? (
              <Alert variant="success">{connectionSuccess}</Alert>
            ) : (
              <></>
            )}
            {modalInfo.body}
          </Modal.Body>
          <Modal.Footer>
            {connectionSuccess === "" ? (
              <>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>

                <Button
                  variant="primary"
                  onClick={handlerActions}
                  disabled={showLoading}
                >
                  {showLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <></>
                  )}
                  Confirm
                </Button>
              </>
            ) : (
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  return (
    <>
      {usersTable()}
      {modalPopUp()}
      <AddUser
        show={showAddNewUSer}
        onCancel={handleCancelNewUser}
        onClose={() => {}}
        onFail={() => {}}
        onSuccess={() => {
          forceSearch();
        }}
      />
      <UpdatePassword
        userDefault={showUpdatePassword.user}
        show={showUpdatePassword.show}
        onCancel={handleCancelUpdatePassword}
        onClose={() => {}}
        onFail={() => {}}
        onSuccess={() => {}}
      />
      <UpdateApplications
        userDefault={showUpdateApplications.user}
        show={showUpdateApplications.show}
        onCancel={handleCancelUpdateApplications}
        onClose={() => {}}
        onFail={() => {}}
        onSuccess={() => {
          forceSearch();
        }}
      />
    </>
  );
}
