import React from "react";
import { getUser } from "../helpers/localStorage";

import { Navigate } from "react-router-dom";
export default function LoginRedirect({ children }) {
  const user = getUser();
  const redirect = "/applications";
  if (typeof user !== "undefined" && typeof user.id !== "undefined") {
    return <Navigate to={redirect} replace />;
  }

  return children;
}
