import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import { updateInfoToUser } from "../../helpers/api";
import { config as applications} from "../pages/Applications/config";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function UpdateApplications({
  show,
  onCancel,
  onClose,
  onSuccess,
  onFail,
  userDefault
}) {
  const defaultApplications = userDefault.applications;
  const [user, setUser] = useState({
    applications: [],
    dueDate: ""
  });
  const [dueDateRise, setDueDateRise] = useState("0");

  const [showLoading, setShowLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [connectionError, setConnectionError] = useState("");
  useEffect(() => {
    setConnectionError("");
    setShowSuccess(false);
  }, [user]);

  useEffect(() => {
    setUser({
      applications: userDefault.applications,
      dueDate: userDefault.dueDate
    });
  }, [show, userDefault.applications]);

  useEffect(()=>{
    if(dueDateRise !== ""){
      let date;
      if(moment().isAfter(moment(userDefault.dueDate))){
          date = moment().add(dueDateRise, "M").toDate();
      }else{
          date = moment(userDefault.dueDate).add(dueDateRise, "M").toDate();
      }
      updateUserInfo({ ...user, dueDate: date });
    }
    
  },[dueDateRise]);

  useEffect(() => {
    const validateInfo = () => {
      let errorInfo = {
        foundError: false,
        applications: ""
      };


      if (typeof user.applications === "undefined" || user.applications.length === 0){
        errorInfo.foundError = true;
        errorInfo.applications = "No selected applications"

      }

      setErrors(errorInfo);
    };
    validateInfo();
  }, [user]);

  const [errors, setErrors] = useState({
    foundError: false,
    applications: ""
  });

  const updateUserInfo = (info) => {
    const data = { ...user, ...info };
    setUser(data);
  };

  const clearBeforeClose = () =>{
    updateUserInfo({ ...user, applications: defaultApplications }); //clear
  }

  const handlerSelectedApplication = (e)=> {
    e.preventDefault();
    let app = [];
    for (var i = 0, l = e.target.options.length; i < l; i++) {
      if (e.target.options[i].selected) {
        app.push(e.target.options[i].value);
      }
    }
  
    updateUserInfo({ ...user, applications: app });
    
  };

  const handleDueDate = (date) => {
    updateUserInfo({ ...user, dueDate: date });
  }

  const handleDueDateRise = (e) => {
    e.preventDefault();
    setDueDateRise(e.target.value);
  };

  const saveUser = (e) => {
    e.preventDefault();
    if (!errors.foundError) {
      setShowLoading(true);
      updateInfoToUser(userDefault, user)
        .then((response) => {
          setShowLoading(false);
          setShowSuccess(true);
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((error) => {
          setShowLoading(false);
          setConnectionError(error);
          setShowSuccess(false);
          if (onFail) {
            onFail();
          }
        });
    }
  };
  const form = () => {
    return (
      <>
        <span style={{ color: "red" }}>{errors.applications}</span>
        <Form.Control as="select" multiple value={user.applications} onChange={handlerSelectedApplication} >
          {applications.applications.map((option,index) => (
            <option key={index} value={option.id}>
              {option.tittle}   
            </option>
          ))}  
        </Form.Control>
        <Form.Group className="m-3">
          <Form.Label>Due Date</Form.Label><br/>
          <DatePicker
                selected={user.dueDate}
                onChange={(date) => handleDueDate(date)}
                highlightDates={[moment(user.dueDate)]}
                isClearable={true}
              />
          <select value={dueDateRise} onChange={handleDueDateRise}>
                <option value="0"></option>
                <option value="1">1 Month</option>
                <option value="2">2 Months</option>
                <option value="3">3 Month</option>
                <option value="4">4 Months</option>
                <option value="5">5 Month</option>
                <option value="6">6 Months</option>
                <option value="7">7 Month</option>
                <option value="8">8 Months</option>
                <option value="9">9 Month</option>
                <option value="10">10 Months</option>
                <option value="11">11 Month</option>
                <option value="12">1 Years</option>
              </select>
        </Form.Group>
      </>
    );
  };

  return (
    <Modal show={show} onHide={()=>{clearBeforeClose();onCancel()}} animation={false} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>{`Update Applications For ${userDefault.username}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {connectionError !== "" ? (
          <Alert variant="warning">{connectionError}</Alert>
        ) : (
          <></>
        )}
        {showSuccess ? (
          <Alert variant="success">Applications Updated!</Alert>
        ) : (
          <></>
        )}
        {form()}
      </Modal.Body>
      <Modal.Footer>
        {!showSuccess ? (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                clearBeforeClose();
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={saveUser} disabled={showLoading}>
              {showLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <></>
              )}
              Save
            </Button>
          </>
        ) : (
          <Button
            variant="secondary"
            onClick={() => {
              clearBeforeClose();
              onCancel();
            }}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
