import React from "react";
import "./spinnerLoader.css";

export default function SpinnerLoader(props) {
  return (
    <div
      id="preloader"
      class={`preloader ${props.show ? "" : "preloader-hidden"}`}
    >
      <div class="inner">
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
      </div>
    </div>
  );
}
