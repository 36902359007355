import axios from "axios";
import { clearAll, generateUUID, getUser, saveUser } from "./localStorage";
import moment from "moment";
const api = process.env.REACT_APP_API;

const axiosAuth = () => {
  const loggedUser = getUser();

  return axios.create({
    headers: {
      Authorization: `Bearer ${loggedUser.accessToken}`,
    },
  });
};

export const getProfile = ()=>{
  return new Promise((resolve, reject) => {
    axiosAuth()
      .get(`${api}/profile`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data.message);
      });
  });
}



export const logIn = ({ username, password }) => {
  const uuid = generateUUID();
  return new Promise((resolve, reject) => {
    axios
      .post(`${api}/login`, { username, password, uuid })
      .then((response) => {
        saveUser(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data.message);
      });
  });
};

export const logOut = () => {
  return new Promise((resolve, reject) => {
    axiosAuth()
      .post(`${api}/logout`)
      .then((response) => {
        clearAll();
        window.location.reload();
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data.message);
      });
  });
  
};

export const updatePassword = (password) => {
  return new Promise((resolve, reject) => {
    axiosAuth()
      .put(`${api}/users`, { password: password })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data.message);
      });
  });
};

export const updateInfoToUser = (user, data) => {
  return new Promise((resolve, reject) => {
    axiosAuth()
      .put(`${api}/users/${user._id}`, { ...data })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data.message);
      });
  });
};

export const deleteSpecificUser = (user) => {
  return new Promise((resolve, reject) => {
    axiosAuth()
      .delete(`${api}/users/${user._id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data.message);
      });
  });
};

export const createUser = (info) => {
  return new Promise((resolve, reject) => {
    axiosAuth()
      .post(`${api}/users`, { ...info })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data.message);
      });
  });
};

export const getUsers = (search) => {
  let dueDate;
  let dueDateSort;
  let enabledBy;
  let enabled;
  if (typeof search.dueDate !== "undefined" && search.dueDate != null && search.dueDate !== ""){
    dueDate = search.dueDateOperator + ":" +moment(search.dueDate).format('YYYY-MM-DD');
  }
  if(typeof search.dueDateSort !== "undefined" && search.dueDateSort != null && search.dueDateSort !== 0){
    dueDateSort = search.dueDateSort;
  }
  if(typeof search.enabledBy !== "undefined" && search.enabledBy != null && search.enabledBy !== ""){
    enabledBy = search.enabledBy;
  }
  if(typeof search.enabled !== "undefined" && search.enabled != null && search.enabled !== "-1"){
    enabled = search.enabled;
  }
  console.log(dueDate);
  return new Promise((resolve, reject) => {
    axiosAuth()
      .get(`${api}/userlist`, { params: { keywords: search.keywords, dueDate: dueDate, orderDueDate: dueDateSort, enabledReason:enabledBy, enabled: enabled} })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data.message);
      });
  });
};

export const clearUserUUIDs = (user) => {
  return new Promise((resolve, reject) => {
    axiosAuth()
      .delete(`${api}/uuid/${user._id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data.message);
      });
  });
};
