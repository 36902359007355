import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import LoginPageKtherobot from "./components/pages/LoginKtherobot/LoginPage";
import LoginPageCardex from "./components/pages/LoginCardex/LoginPage";
import Menu from "./components/menu/Menu";
import ApplicationsPage from "./components/pages/Applications/ApplicationPage";
import UserManagementPage from "./components/pages/UserManagement/UserManagementPage";
import UserPage from "./components/pages/user/userPage";
import PrivateRoute from "./routes/PrivateRoute";
import LoginRedirect from "./routes/LoginRedirect";
import { getUser } from "./helpers/localStorage";
const configSystem = require("./configApp");

export default function App() {
  return (
    <div>
      <Routes>
        <Route
          exact
          path="/"
          element={<Navigate to="/login" replace={true} />}
        />
        <Route
          path="/applications"
          element={
            <PrivateRoute>
              <Menu username={getUser().username}>
                <ApplicationsPage />
              </Menu>
            </PrivateRoute>
          }
        />
        <Route
          path="/user-management"
          element={
            <PrivateRoute
              roles={[configSystem.roles.SUPER_ADMIN, configSystem.roles.ADMIN]}
            >
              <Menu username={getUser().username}>
                <UserManagementPage />
              </Menu>
            </PrivateRoute>
          }
        />
        <Route
          path="/login"
          element={
            <LoginRedirect>
              {process.env.REACT_APP_REFERENCE_APP === "ktherobot" ? (
                <LoginPageKtherobot />
              ) : (
                <LoginPageCardex />
              )}
            </LoginRedirect>
          }
        />
        <Route
          path="/change-password"
          element={
            <PrivateRoute>
              <Menu username={getUser().username}>
                <UserPage />
              </Menu>
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/login" replace={true} />} />
      </Routes>
    </div>
  );
}
