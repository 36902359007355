import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import validator from "validator";
import { createUser } from "../../helpers/api";
import { config as applications} from "../pages/Applications/config";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const configSystem = require("../../configApp");


export default function AddUser({
  show,
  onCancel,
  onClose,
  onSuccess,
  onFail,
}) {
  const [dueDateRise, setDueDateRise] = useState("");
  const [user, setUser] = useState({
    username: "",
    passowrd: "",
    rpassword: "",
    type: configSystem.roles.BASIC_CUSTOMER,
    enabled: true,
    dueDate: "2050-07-20",
    applications: [],
  });

  const [showLoading, setShowLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [connectionError, setConnectionError] = useState("");
  useEffect(() => {
    setConnectionError("");
    setShowSuccess(false);
  }, [user]);

  useEffect(() => {
    setUser({
      username: "",
      passowrd: "",
      rpassword: "",
      type: configSystem.roles.BASIC_CUSTOMER,
      enabled: true,
      applications: [],
    });
  }, [show]);

  useEffect(()=>{
    if(dueDateRise !== ""){
      const date = moment().add(dueDateRise, "M").toDate();
      updateUserInfo({ ...user, dueDate: date });
    }
    
  },[dueDateRise]);

  useEffect(() => {
    const validateInfo = () => {
      let errorInfo = {
        foundError: false,
        email: "",
        password: "",
        rpassword: "",
      };

      if (!validator.isEmail(user.username)) {
        if (user.username !== "") errorInfo.email = "Not valid email!";
        errorInfo.foundError = true;
      }

      if (user.password === "" || typeof user.password === "undefined") {
        errorInfo.foundError = true;
      } else {
        if (validator.isLength(user.password, { min: 0, max: 7 })) {
          if (validator.isLength(user.password, { min: 1, max: 7 }))
            errorInfo.password = "Pasword should at least be 8 characteres!";
          errorInfo.foundError = true;
        }
      }

      if (user.password !== user.rpassword) {
        if (user.rpassword.toString().trim().length > 0)
          errorInfo.rpassword = "Password don't match!";
        errorInfo.foundError = true;
      }

      setErrors(errorInfo);
    };
    validateInfo();
  }, [user]);

  const [errors, setErrors] = useState({
    foundError: false,
    email: "",
    password: "",
    rpassword: "",
  });

  const updateUserInfo = (info) => {
    const data = { ...user, ...info };
    setUser(data);
  };

  const handlerEmail = (e) => {
    e.preventDefault();
    updateUserInfo({ ...user, username: e.target.value });
  };

  const handlerPasword = (e) => {
    e.preventDefault();
    updateUserInfo({ ...user, password: e.target.value ? e.target.value : "" });
  };
  const handlerRPasword = (e) => {
    e.preventDefault();
    updateUserInfo({ ...user, rpassword: e.target.value });
  };

  const handleDueDateRise = (e) => {
    e.preventDefault();
    setDueDateRise(e.target.value);
  };

  const handlerEnable = (e) => {
    updateUserInfo({ ...user, enabled: e.target.checked });
  };

  const handleDueDate = (date) => {
    updateUserInfo({ ...user, dueDate: date });
  }

  const handlerSelectedApplication = (e)=> {
    e.preventDefault();
    let app = [];
    for (var i = 0, l = e.target.options.length; i < l; i++) {
      if (e.target.options[i].selected) {
        app.push(e.target.options[i].value);
      }
    }
  
    updateUserInfo({ ...user, applications: app });
  }

  const saveUser = (e) => {
    e.preventDefault();
    if (!errors.foundError) {
      setShowLoading(true);
      createUser(user)
        .then((response) => {
          setShowLoading(false);
          setShowSuccess(true);
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((error) => {
          setShowLoading(false);
          setConnectionError(error);
          setShowSuccess(false);
          if (onFail) {
            onFail();
          }
        });
    }
  };

  const form = () => {
    return (
      <>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            value={user.username}
            onChange={handlerEmail}
          />
          <span style={{ color: "red" }}>{errors.email}</span>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="******"
            value={user.password}
            onChange={handlerPasword}
          />
          <span style={{ color: "red" }}>{errors.password}</span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Repeat Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="******"
            value={user.rpassword}
            onChange={handlerRPasword}
          />
          <span style={{ color: "red" }}>{errors.rpassword}</span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Enable"
            checked={user.enabled}
            onChange={handlerEnable}
          />
        </Form.Group>

        
        
        <Form.Control as="select" multiple value={user.applications} onChange={handlerSelectedApplication} >
          {applications.applications.map((option,index) => (
            <option key={index} value={option.id}>
              {option.tittle}   
            </option>
          ))}  
        </Form.Control>

        <Form.Group className="m-3">
          <Form.Label>Due Date</Form.Label><br/>
          <DatePicker
                selected={user.dueDate}
                onChange={(date) => handleDueDate(date)}
                highlightDates={[moment(user.dueDate)]}
                isClearable={true}
              />
          <select value={dueDateRise} onChange={handleDueDateRise}>
                <option value="0"></option>
                <option value="1">1 Month</option>
                <option value="2">2 Months</option>
                <option value="3">3 Month</option>
                <option value="4">4 Months</option>
                <option value="5">5 Month</option>
                <option value="6">6 Months</option>
                <option value="7">7 Month</option>
                <option value="8">8 Months</option>
                <option value="9">9 Month</option>
                <option value="10">10 Months</option>
                <option value="11">11 Month</option>
                <option value="12">1 Years</option>
              </select>
        </Form.Group>
      </>
    );
  };

  return (
    <Modal show={show} onHide={onCancel} animation={false} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Add New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {connectionError !== "" ? (
          <Alert variant="warning">{connectionError}</Alert>
        ) : (
          <></>
        )}
        {showSuccess ? <Alert variant="success">User Created!</Alert> : <></>}
        {form()}
      </Modal.Body>
      <Modal.Footer>
        {!showSuccess ? (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={saveUser} disabled={showLoading}>
              {showLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <></>
              )}
              Save
            </Button>
          </>
        ) : (
          <Button
            variant="secondary"
            onClick={() => {
              onCancel();
            }}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
