const configSystem = require("../../configApp");
const { logOut } = require("../../helpers/api");

exports.config = {
  main: [
    { text: "Applications", to: "/applications" },
    {
      text: "User Management",
      to: "/user-management",
      roles: [configSystem.roles.ADMIN, configSystem.roles.SUPER_ADMIN],
    },
    { text: "Change Password", to: "/change-password" },
  ],
  bottom: [{ text: "Log Out", action: logOut }],
};
