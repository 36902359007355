import React, {useEffect} from "react";
import { getUser, clearAll } from "../helpers/localStorage";
import { Navigate, useNavigate } from "react-router-dom";
import { getProfile } from "../helpers/api";

export default function PrivateRoute({ roles, children }) {
  const user = getUser();
  const redirect = "/";
  let role = undefined;
  const navigate = useNavigate();

  useEffect(()=>{
    if (typeof user !== "undefined" && user !== null && typeof user.id !== "undefined" && typeof user.accessToken !== "undefined") {
      getProfile().then(()=>{}).catch((data)=>{
        console.log(data);
        clearAll();
        navigate(redirect);
      });
    }
    
   
  },[navigate, user])
  
  if (typeof user === "undefined" || typeof user.id === "undefined") {
    return <Navigate to={redirect} replace />;
  }

  if (typeof roles !== "undefined") {
    role = roles.find((thisRole) => thisRole === user.type);
    if (typeof role === "undefined") {
      return <Navigate to={redirect} replace />;
    }
  }

  
  return children;
}
